<template>
    <div>
        <Loading :isLoading="isLoading" />
        <div class="mb-2">
          <p style="padding:10px 10px 0px 10px;font-size:20px;color:#810042;font-weight:700;font-family:Lato;">Candidatos a Crédito</p>
        </div>
        <TableComponent :key="render" :column_keys="exportColumnsCsv" :columns="columns" :rows="rows" :rangeInput="true" :exportInput="true" v-on:rowsSelectedChanged="changeRowsSelected($event)" />
    </div>
</template>

<script>
import { onBeforeMount, ref } from 'vue';

import TableComponent from "@/components/Forms/Tables/TableComponent";
import TableButton from "@/components/Forms/Tables/TableButton";
import Loading from "@/components/Loading/VueLoading.vue";

import Loans from "@/classes/Loans.js";
import { getToken } from "@/helpers/tokenauth.js";
import SwalAlertHelper from "@/helpers/swal-alert";

import { formatCurrency } from "@/helpers/filters";

export default {
    setup(){
        
        const rows = ref([]);
        const isLoading = ref(true);
        const render = ref(false);
        const columns = ref([]);
        const exportColumnsCsv = [
            "label_column_rfc_loan_candidates",
            "label_column_candidate_name_loan_candidates",
            "label_column_total_saved_loan_candidates",
            "label_column_authorization_expiration_date_loan_candidates",
            "label_column_preauthorized_maximum_amount_loan_candidates",
        ];
        const getColumns = () => {
            columns.value = [
                { headerName: "RFC", field: "rfc", colId: "label_column_rfc_loan_candidates",  headerCheckboxSelection: true, resizable: true, sortable: true, filter: true, checkboxSelection: true , cellRenderer: (params) => setTableAttributeId(`label_rfc_loan_candidates_${params.data.client_id}`, params.value)},
                { headerName: "Nombre Completo", field: "name", colId: "label_column_candidate_name_loan_candidates", cellRenderer: (params) => setTableAttributeId(`label_candidate_name_loan_candidates_${params.data.client_id}`, params.value) },
                { headerName: "Total ahorrado", field: "current_balance_cents", colId: "label_column_total_saved_loan_candidates", cellRenderer: (params) => setTableAttributeId(`label_total_saved_loan_candidates_${params.data.client_id}`, params.value) },
                { headerName: "Fecha de expiración pre-autorizo", minWidth: 280, flex: 1, resizable: false, sortable: true, field: "expiration_date", colId: "label_column_authorization_expiration_date_loan_candidates", cellRenderer: (params) => setTableAttributeId(`label_authorization_expiration_date_loan_candidates_${params.data.client_id}`, params.value)},
                { headerName: "Cantidad máxima pre-autorizada", minWidth: 280, field: "credit_limit", colId: "label_column_preauthorized_maximum_amount_loan_candidates",  flex: 1, resizable: false, sortable: true, filter: true,  
                    cellRenderer: params => {
                        const input = document.createElement("input");
                        input.type = "number";
                        input.value = params.value;
                        input.setAttribute('id', `input_cauthorization_expiration_date_loan_candidates_${params.data.client_id}`);
                        input.className += "w-full h-8 border-2 bg-white focus:bg-blue-700 mx-auto cursor-pointer pl-4";
                        input.addEventListener("input", e => {
                            e.preventDefault();
                            validateMaxAmount(input.value);
                        });
                        return input;
                    }
                },
                { headerName: "", field: "client_id", colId: "label_column_input_update_preauthorized_maximum_amount_loan_candidates",  filter: false, minWidth: 280,  cellRenderer: params => {
                    const element = document.getElementById(`input_cauthorization_expiration_date_loan_candidates_${params.value}`);
                    const clientId = params.value;
                    const button = document.createElement("label");
                    button.innerText = "Actualizar";
                    button.className += "bg-cherryColor text-white focus:bg-blue-700 px-10 py-1 mx-auto rounded-md cursor-pointer";
                    button.addEventListener("click", e => {
                        e.preventDefault();
                        const amount = element.value;
                        if(!validateMaxAmount(amount))return;

                        updateCredit(clientId, amount);
                    });
                    return button;
                }},
            ];
        }

        const LoansClass = new Loans();
        
        onBeforeMount(async()=> {
            await getLoandCandidates();
            isLoading.value = false;
            getColumns();
            render.value = !render.value;
        });

        const validateMaxAmount = (amount) => {
            if(amount == 0 && amount != ""){
                 SwalAlertHelper.showMessage('error', 'Error', 'La cantidad máxima pre-autorizada debe ser mayor a cero.');
                 return false;
            }

            if(!isInteger(amount)){
                SwalAlertHelper.showMessage('error', 'Error', 'La cantidad máxima pre-autorizada debe ser entero.');
                return false;
            }

            return true;
        }

        const isInteger = (number) => {
            return /^[+]?\d+$/.test(String(number));
        }

        const setTableAttributeId = (identification, value) => {
            return `<span id="${identification}"> ${(value !== null)? value: ''} </span>`;
        };

        const updateCredit = async (clientId, amount) => {
            const response = await LoansClass.updateCreditLimit(getToken(), clientId, amount).then(resp => {
                return { status: true, message: resp.data.response.message, data: null};
            }).catch(err => {
                return { status: false, message: err.response.data.response.message, data: null };
            });

            SwalAlertHelper.showMessage((response.status)? 'success': 'error', (response.status)? 'Aviso': 'Error', response.message);
            
            await getLoandCandidates();
            render.value = !render.value;
        }
        
        const getLoandCandidates = async () => {
            let response = { status: undefined,  message: undefined, data: undefined };

            response = await LoansClass.getActiveLoansCandidates(getToken()).then(resp => {
                return { status: true, message: resp.data.response.message, data: resp.data.response.result};
            }).catch(err => {
                return { status: false, message: 'Ha ocurrido un error al consultar la lista de Candidatos a Crédito', data: null };
            });

            if(!response.status) SwalAlertHelper.showMessage((response.status)? 'success': 'error', (response.status)? 'Aviso': 'Error', response.message);
            
            rows.value = getFinalArrayList(response.data);
            render.value = !render.value;
        }

        const getFinalArrayList = (array) => {
            let finalArray = [];
            try {
                for (let i = 0; i < array.length; i++) {
                    let id =  array[i].client.id;
                    let rfc = array[i].client.rfc;
                    let name = `${array[i].client.name} ${(array[i].client.middle_name == null)? ' ': array[i].client.middle_name} ${array[i].client.paternal_name} ${array[i].client.maternal_name} `.trim();
                    let expiration_date = array[i].client.loan_candidate.expiration_date;
                    let current_balance_cents = formatCurrency(array[i].client.savings.current_balance_cents / 100);
                    let credit_limit = array[i].client.credit_limit.amount_cents / 100;
                    
                    finalArray.push({
                        id,
                        rfc,
                        name,
                        expiration_date,
                        current_balance_cents,
                        credit_limit,
                        client_id: id
                    });
                }
            } catch (error) {
                console.log(error)
            }

            return finalArray;
        }

        const changeRowsSelected = (values) => {
            console.log("changeRowsSelected ~ values:", values)
        }

        return {
            isLoading,
            render,
            rows,
            changeRowsSelected,
            columns,
            updateCredit,
            exportColumnsCsv
        };
    },
    components: {
        TableComponent,
        TableButton,
        Loading
    }
}

</script>