<template>
    <html>
      <body class="" style="background: #FFF">
        <Header />
          <MenuLateralMobile />
          <div class="h-screen grid grid-cols-6 grid-rows-12 grid-flow-col">
             <MenuLateral/>
              <div class="col-span-6" style="background:#FFF;padding:20px;">
                <CreditCustomerComponent />
                <Footer/>
              </div>
          </div>
      </body>
    </html>
  </template>
  
  <script>
  import CreditCustomerComponent from "@/components/Administrative/Credit/CreditCustomers.vue";
  import Header from "@/components/Administrative/Header/Header.vue";
  import MenuLateralMobile from '@/components/MenuLateralMobile.vue';
  import MenuLateral from '@/components/LateralMenu.vue';
  import Footer from '@/components/Analyst/Footer.vue';

  export default {
    name: 'CreditCursomter',
    components: {
      Header,
      CreditCustomerComponent,
      MenuLateral,
      MenuLateralMobile,
      Footer
    }
  }
  </script>
  